<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
        <span class="itemHeading"
            >IB Closedowns</span
          >
          <v-spacer></v-spacer>
          <v-flex pt-5 xl3 lg3 md3 pb-2 sm4 xs12 px-5>
            <!-- <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="600px"
              :key="dialog"
            > -->
              <!-- <template v-slot:activator="{ on, attrs }"> -->
                <v-btn
                  style="font-family: poppinsmedium"
                  outlined
                  block
                  color="success"
                  to="/Admin/addIBClosed"
                >
                  Add Closedown
                </v-btn>
              <!-- </template> -->
              <!-- <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-card-title>
                    <span class="headline">Add Category</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="name"
                            label="Category Name"
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogclose()">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="categoryadd()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card> -->
            <!-- </v-dialog> -->
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in closedlist"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile flat outlined>
              <v-layout wrap justify-center py-4>
                <v-flex xs12 px-4 py-2 text-left>
                  <span style="font-family: poppinsbold">{{
                    item.remark
                  }}</span>
                </v-flex>
                <v-flex xs12   px-4 sm4 text-left>
                  <span style="font-family: poppinsbold">From</span>
                </v-flex>
                <v-flex xs12 sm8 px-4 px-sm-0 text-left>
                  <span style="font-family: poppinsmedium">{{
                    item.start.slice(0,10)
                  }}</span>
                </v-flex>
                <v-flex xs12  px-4 sm4 text-left>
                  <span style="font-family: poppinsbold">To</span>
                </v-flex>
                <v-flex xs12 sm8 px-4 px-sm-0 text-left>
                  <span style="font-family: poppinsmedium">{{
                    item.end.slice(0,10)
                  }}</span>
                </v-flex>
                <!-- </v-layout>
                <v-layout wrap justify-center py-4> -->
                <v-flex xs12 align-self-center px-4 py-2>
                  <v-btn
                    color="success"
                    style="font-family: poppinsmedium"
                    small
                    block
                    outlined
                    :to="'/Admin/editIBClosed?id='+item._id"
                    >Edit</v-btn
                  >
                </v-flex>
                <v-flex xs12 align-self-center px-4 py-2> 
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Closure?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Category</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="editingitem.name"
                      label=" Category"
                      :rules="[rules.required]"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="getdData">
                  Close
                </v-btn>
                <v-btn
                  v-if="!flag"
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      flag: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      headers: [
        { text: "Category Name", value: "name" },
        // { text: "Username", value: "username" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      category: [],
      data: [],
      closedlist:[]
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    getdData(){
      this.editdialog = false
      this.getData()
    },
    initialize() {
      this.admin = [];
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/closed/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/closed/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params:{
          start: this.from,
          end: this.to,
          isIB: true
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.closedlist = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    categoryadd() {
      var data = {};
      data["name"] = this.name;
      axios({
        url: "/category/add",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      axios({
        url: "/category/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>